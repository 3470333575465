<template>
  <div>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="delinquents"
        :page.sync="page"
        :items-per-page="delinquentsPerPage"
        :server-items-length="totalDelinquents"
        hide-default-footer
        :single-select="singleSelect"
        show-select
        @page-count="pageCount = $event"
        sort-by="name"
        class="elevation-1 ml-4"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-col
            cols="12"
            sm="3"
          >
          <v-select
            :items="intakes"
            item-text="name"
            item-value="id"
            v-model="intakeId"
            dense
            outlined
            color="grey"
            hint="Filter by Intake"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
          <v-select
            :items="allDistricts"
            item-text="name"
            item-value="id"
            v-model="residenceId"
            dense
            outlined
            color="grey"
            hint="Filter by District"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
          <v-text-field
            v-model="delinquentFirstName"
            dense
            outlined
            color="grey"
            hint="Filter by Name"
            persistent-hint
            class="mt-8 mx-4"
            solo
          />
          </v-col>
          <v-btn
            class="mt-2"
            elevation="1"
            fab
            small
            @click="loadAllDelinquents"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn rounded disabled right>{{currentCenter.name}}</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.names="{ item }">
          <p @click="loadProfile(item)" class=" btn blue--text">{{ item.names }}</p>
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="loadProfile(item)"
          >
            mdi-eye
          </v-icon>
        </template> -->
      </v-data-table>
      <v-card class="ml-4 mt-1">
        <v-row>
          <v-col
          cols="12"
          sm="2"
        >
        <v-select
          :items="graduations"
          item-text="name"
          item-value="id"
          v-model="graduation"
          dense
          label="Select Graduation"
          outlined
          color="grey"
          class="mx-4"
          solo
        />
        </v-col>
        <v-col
          cols="12"
          sm="2"
        >
        <v-btn class="green" dense @click="addDialog = true">Save</v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="2">
          <p class="mx-4">{{selected.length}} Selected </p>
        </v-col>
        </v-row>
        </v-card>
        <div class="pt-2 px-12">
        <v-row>
        <v-col cols="12" sm="2">
        <v-select
          :items="selectDelinquentsPerPage"
          v-model="delinquentsPerPage"
          label="Delinquents per page"
          dense
        ></v-select>
        </v-col>
        <v-col cols="12" sm="8">
        <v-pagination
          v-model="page"
          :length="pageCount"
          circle
          total-visible="7"
          color="blue"
        ></v-pagination>
        </v-col>
        <v-col cols="12" sm="2">
          {{totalDelinquents}} Records
        </v-col>
        </v-row>
      </div>
  <v-snackbar
      v-model="snackbar"
      top
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          icon
          v-bind="attrs"
          @click="setSnackbar(false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="addDialog" hide-overlay max-width="500px">
    <v-card tile outlined>
        <v-card-text>
          <p>You are about to add {{selected.length}} delinquents to graduation list. Continue ?</p>
          <v-btn link small color="blue" @click="addToList()">Yes</v-btn>
          <v-btn link small color="blue" @click="addDialog = false">No</v-btn>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      dialog: false,
      privileges: null,
      showCreate: false,
      addDialog: false,
      names: null,
      headers: [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'number'
        },
        { text: 'Names', value: 'names' },
        { text: 'Year Of Birth', value: 'delinquent.dateOfBirth' },
        { text: 'District of Residence', value: 'apprehension.delinquentResidence.district.name' },
        { text: 'Arrival Date', value: 'arrivalDate' },
        { text: 'Intake', value: 'intakeName' }
      ],
      delinquents: [],
      graduations: [],
      graduation: null,
      trainings: [],
      selectDelinquentsPerPage: [2, 5, 10, 15, 20, 25, 30],
      page: 1,
      pageCount: 0,
      delinquentsPerPage: 20,
      totalDelinquents: 0,
      singleSelect: false,
      intakes: [],
      emptyIntake: { id: null, name: '' },
      intake: null,
      allDistricts: [],
      emptyDistrict: { id: null, name: '' },
      selected: [],
      transferId: null,
      errors: null,
      panel: 3,
      delinquentFirstName: null,
      delinquentLastName: null,
      placeOfBirthId: null,
      residenceId: null,
      recordDates: null,
      arrivalDates: null,
      exitDates: null,
      released: null,
      states: null,
      eligibilityStates: null,
      intakeId: null,
      intakeSet: null,
      decisionIds: null,
      physicallyScreened: null,
      screeningApprovalStatuses: null,
      onGraduationList: null,
      graduationId: null,
      exapnd: null,
      graduationApprovalStatuses: null,
      postRehabDestinationId: null,
      hasOccupation: null,
      organizationId: null,
      sort: 'recordDate,desc'
    }
  },

  computed: {
    ...mapState('store', ['snackbar', 'color', 'text', 'timeout', 'currentUser', 'currentCenter', 'search', 'storeProvinces', 'storeProvincesError', 'deviantActs', 'identificationDocuments', 'apprehensionItems'])
  },

  watch: {
    page: {
      handler () {
        this.loadAllDelinquents()
      }
    },
    delinquentsPerPage: {
      handler () {
        this.page = 1
        this.loadAllDelinquents()
      }
    }
  },
  mounted () {
    this.loadAllDelinquents()
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  created () {
    this.loadIntakes()
    this.loadAllDistricts()
    this.loadGraduations()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapActions('store', ['loadStoreProvinces']),
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      setDelinquentItem: 'SET_DELINQUENT_ITEM'
    }),
    loadAllDelinquents () {
      const pageNumber = this.page - 1
      this.states = 'REHABILITATION'
      this.intakeSet = true
      this.delinquentLastName = this.delinquentFirstName
      this.onGraduationList = false
      this.$delinquentService.fetchAllTransfersSpecification(this.delinquentFirstName, this.delinquentLastName, this.placeOfBirthId, this.residenceId, this.recordDates, this.arrivalDates, this.exitDates, this.released, this.states, this.eligibilityStates, this.intakeId, this.intakeSet, this.decisionIds, this.physicallyScreened, this.screeningApprovalStatuses, this.onGraduationList, this.graduationId, this.exapnd, this.graduationApprovalStatuses, this.postRehabDestinationId, this.hasOccupation, this.organizationId, pageNumber, this.delinquentsPerPage, this.sort)
        .then(response => {
          if (!response.status) {
            this.delinquents = response.content
            this.totalDelinquents = response.totalElements
            this.delinquents.forEach(element => {
              element.names = element.delinquent.firstName + ' ' + element.delinquent.lastName
              element.delinquent.dateOfBirth = this.$moment(element.delinquent.dateOfBirth).format('YYYY')
              element.arrivalDate = this.$moment(element.arrivalDate).format('DD-MMM-YYYY')
              element.number = response.content.indexOf(element) + 1
            })
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText('Read Delinquents: ' + response.message)
          }
        })
    },
    loadGraduations () {
      this.$delinquentService.fetchGraduations(null, null, 'OPEN', null, 'PENDING,REQUESTED,REJECTED')
        .then(response => {
          if (!response.status) {
            this.graduations = response.content
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    loadProfile (item) {
      this.setDelinquentItem(item)
      this.$router.push('/delinquent-profile')
    },
    loadIntakes () {
      this.$delinquentService.fetchIntakes('OPEN')
        .then(response => {
          if (!response.status) {
            this.intakes = response
            this.intakes.unshift(this.emptyIntake)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    loadAllDistricts () {
      this.$locationService.fetchAllDistricts()
        .then(response => {
          if (!response.status) {
            this.allDistricts = response
            this.allDistricts.unshift(this.emptyDistrict)
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    addToList () {
      this.selected.forEach(element => {
        delete element.arrivalDate
        delete element.apprehension
        delete element.center
        delete element.eligibilityState
        delete element.intakeName
        delete element.recordDate
        delete element.names
        delete element.receiver
        delete element.state
        delete element.delinquent
      })
      this.$delinquentService.addGraduantsToList(this.graduation, this.selected)
        .then(response => {
          if (response.status === 'success') {
            this.loadAllDelinquents()
            this.setSnackbar(true)
            this.setColor('green')
            this.setText('Delinquents added to graduation list successfully')
            this.addDialog = false
            this.selected = []
            this.graduation = []
          } else {
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    checkPrivileges () {
      this.privileges.includes('CREATE_DELINQUENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showCreate = true : this.showCreate = false
      this.privileges.includes('UPDATE_DELINQUENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showEdit = true : this.showEdit = false
      this.privileges.includes('DELETE_DELINQUENTS') || this.privileges.includes('ALL_AUTHORITIES') ? this.showDelete = true : this.showDelete = false
    }
  }
}
</script>
<style scoped>
.btn {
  cursor: pointer;
}
</style>
